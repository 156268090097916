.list-group {
    .list-group-item {
        border-color: transparent;
        background-color: transparent;
        padding: 10px 15px;
        &.disabled {
            background-color: transparent;
            color: #ccc;
        }
        &.active {
            background-color: transparent;
            border-color: transparent;
            color: $green;
        }
    }
    &.list-group-full .list-group-item {
        padding-left: 0;
        padding-right: 0;
    }

    &.list-group-divider .list-group-item:not(:first-child) {
        border-top-color: #e1eaec;
    }

    &.list-group-bordered {
        .list-group-item {
            border-color: #e1eaec;
            &.active {
                background-color: $brand-primary;
                border-color: $brand-primary;
                color: #fff;
            }
            &:first-child {
                border-top-left-radius: 3px;
                border-top-right-radius: 3px;
            }
            &:last-child {
                border-bottom-left-radius: 3px;
                border-bottom-right-radius: 3px;
            }
        }
    }

    @include list-group-item-variant(success, mix(white, $brand-success, 85%), mix(black, $brand-success, 28%));
    @include list-group-item-variant(info, mix(white, $brand-info, 85%), mix(black, $brand-info, 28%));
    @include list-group-item-variant(warning, mix(white, $brand-warning, 85%), mix(black, $brand-warning, 28%));
    @include list-group-item-variant(danger, mix(white, $brand-danger, 85%), mix(black, $brand-danger, 28%));
}


// Media list
.media {
    margin: 0;
    .media-heading {line-height: 1.1; font-weight:600; margin-bottom: 5px;}
    .media-img {padding-right:14px}
}
.media-list {
    padding: 0;
    list-style: none;
    .media {padding: 8px 0}
}


.media-right .media-img {
    padding-left: 14px;
    padding-right: 0
}

.media-list.media-list-divider .media:not(:first-child) {border-top: 1px solid #e1eaec;}