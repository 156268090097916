/************************************************************************************
smaller than 980
*************************************************************************************/

@media screen and (max-width: 980px) {
	/* embedded videos */
	.video embed,
	.video object,
	.video iframe {
		width: 100%;
		height: auto;
		min-height: 300px;
	}
}


/************************************************************************************
smaller than 768
*************************************************************************************/

@media screen and (max-width: 768px) {
	body:not(.sidebar-mini):not(.drawer-sidebar) 
	.dashboard-wrapper {
	    margin-left: 0;
	}
}









/************************************************************************************
smaller than 650
*************************************************************************************/
@media screen and (max-width: 650px) {

	/* embedded videos */
	.video embed,
	.video object,
	.video iframe {
		min-height: 250px;
	}

}

/************************************************************************************
smaller than 560
*************************************************************************************/
@media screen and (max-width: 480px) {


}
