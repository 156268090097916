
.modal-dialog {
  z-index: 1100;

  .modal-content {
    border: 0;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, .14), 0 4px 5px 0 rgba(0, 0, 0, .098), 0 1px 10px 0 rgba(0, 0, 0, .084);
  }
}

.modal-header {
  align-items: center;
  background: $brand-primary;
  color: $white;
}

.modal-header .close {
  margin: 0;
  font-size: 0;
  color: $white;
  font-family: "Font Awesome 5 Free";
  speak: none;
  font-style: normal;
  font-variant: normal;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  opacity: .5;
  cursor: pointer;
  padding: 0.25rem 0;

  &::before {
    font-weight: 900;
    content: "\f00d";
    font-size: 13px;
    line-height: 1;
  }

  &:hover {
    opacity: .75;
  }

  span {
    display: none;
  }
}
