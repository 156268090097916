
.card-title {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 10px;
    margin-top: 0;
}

.card-subtitle {
  margin-top: -10px;
  margin-bottom: 10px;
  font-size: 13px;
}

.card-header, .card-footer {background-color: inherit}