
@each $name, $color in $scotch-colors {
	@include bg-variant($name, $color);
}

.bg-white { background-color: $white !important; }
.bg-transparent { background-color: transparent !important; }

.bg-primary { background-color: $brand-primary !important }
.bg-success { background-color: $brand-success !important }
.bg-info { background-color: $brand-info !important }
.bg-warning { background-color: $brand-warning !important }
.bg-danger { background-color: $brand-danger !important }
