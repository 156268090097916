.bootstrap-table {

  .fixed-table-container {

    .fixed-table-body {

      .fixed-table-loading {

        .loading-wrap {

          .loading-text {
            font-size: 1rem;
          }
        }
      }
    }
  }

  .fixed-table-pagination {

    .pagination-detail {

      .caret {
        display: none;
      }
    }
  }

  .action-btns {

    a, button {
      min-width: 2.3rem;
      text-align: center;
    }
  }
}
