.no-margin {margin: 0 !important}
.no-padding {padding: 0 !important}

.m-t-0 {margin-top: 0px !important} 
.m-t-5 {margin-top: 5px !important} 
.m-t-10 {margin-top: 10px !important} 
.m-t-15 {margin-top: 15px !important} 
.m-t-20 {margin-top: 20px !important}

.m-b-0 {margin-bottom: 0px !important} 
.m-b-5 {margin-bottom: 5px !important} 
.m-b-10 {margin-bottom: 10px !important} 
.m-b-15 {margin-bottom: 15px !important} 
.m-b-20 {margin-bottom: 20px !important}

.m-r-5 {margin-right: 5px !important} 
.m-r-10 {margin-right: 10px !important} 
.m-r-15 {margin-right: 15px !important} 
.m-r-20 {margin-right: 20px !important}

.m-l-5 {margin-left: 5px !important} 
.m-l-10 {margin-left: 10px !important} 
.m-l-15 {margin-left: 15px !important} 
.m-l-20 {margin-left: 20px !important}


.p-b-5 {padding-bottom: 5px !important}
.p-b-10 {padding-bottom: 10px !important}
.p-b-20 {padding-bottom: 20px !important}

.p-r-5 {padding-right: 5px !important}
.p-r-10 {padding-right: 10px !important}
.p-r-20 {padding-right: 20px !important}

.p-l-5 {padding-left: 5px !important}
.p-l-10 {padding-left: 10px !important}
.p-l-20 {padding-left: 20px !important}

.p-t-5 {padding-top: 5px !important}
.p-t-10 {padding-top: 10px !important}
.p-t-20 {padding-top: 20px !important}