@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap');
/*********** vars ***************/
$red-color: #FF0000;
$orange-color: #f7a400;
$text-color: #FF0000;

/*********** main ***************/
body{
	background:#000;
	font-family: 'Open Sans', sans-serif;
	font-size: 16px;
	letter-spacing: 0.025em;//testowo
}
/******** margin-test*********/
.mt-4{
    margin-top: 15px!important;
}
.mb-4{
    margin-bottom: 15px!important;
}
.mt-5{
    margin-top: 30px!important;
}
.mt-6{
    margin-top: 45px!important;
}
.mb-5{
    margin-bottom: 30px!important;
}
/******** margin-test*********/
.is-invalid{
	label{
		color:#dc4c64!important;
	}
	.form-notch-leading {
		border-bottom: 1px solid #dc4c64!important;
	}
	.form-notch-middle {
		border-bottom: 1px solid #dc4c64!important;
	}
	.form-notch-trailing{
		border-bottom: 1px solid #dc4c64!important;
	}
	input{
		color:#dc4c64;
	}
}
.btn {
	filter: saturate(1.5);
	}
.btn-primary{
	width: 100%;
    border: 1px solid $red-color;
    -webkit-box-shadow: 0;
    box-shadow: none!important;
	line-height: 36px;
	background-color: red;
    border: 1px solid red;
    box-shadow: none!important;
    color: #fff!important;
	border-radius: 0px;
    font-size: 16px;
    height: auto;
    margin-top: 15px;
    display: flex;
    padding: 15px;
    justify-content: center;
    text-transform: none;
    width: 100%;
	@media(max-width:768px){
		letter-spacing: 0.5px;
		padding: 10px;
	}
	
	&:hover, &:active, &:disabled, &:focus{
		background-color: #000!important;
		border: 1px solid $red-color!important;
		box-shadow: none!important;
	}
	.active, .disabled, .focus {
	    background-color: #000!important;
		border: 1px solid $red-color!important;
		box-shadow: none!important;
	}
}
.grecaptcha-badge{
    display:none!important;
}
.btn-empty {
	background:#000;
	&:hover, &:active, &:disabled, &:focus{
		background-color: $red-color!important;
		border: 1px solid $red-color!important;
		box-shadow: none!important;
	}
}
a{
	color: $orange-color;
	text-decoration:none;
	transition: all 0.6s ease;
	&:hover, &:focus, &:active{
		opacity:0.64;
		color: $orange-color;
	}
}
#datetimepickerSummaryMain{
	input{
		&:invalid{
			margin-bottom:0!important;
		}
	}
}
.form-control{
	height:48px;
    padding: 0!important;
	font-size:16px;
    color: #fff;
	&:focus {
		color: #fff;
		background-color: #000;
		border-color:#000!important;
	}
}
.xdsoft_datetimepicker .xdsoft_calendar td, .xdsoft_datetimepicker .xdsoft_calendar th {
    background: #000;
	color:$orange-color;
	border:0;
}
.xdsoft_datetimepicker .xdsoft_label i {
	transition: background-color 300ms linear;
    -webkit-transition: background-color 300ms linear;
    -ms-transition: background-color 300ms linear;
    -o-transition: background-color 300ms linear;
    -ms-transition: background-color 300ms linear;
}
.xdsoft_datetimepicker{
    background: #000;
    border: 1px solid $orange-color;
    border-radius: 0;
	color:$orange-color;
	padding:15px;
}
.xdsoft_datetimepicker .xdsoft_month {
    text-align: center;
    width: 156px;
}
.xdsoft_datetimepicker .xdsoft_label>.xdsoft_select.xdsoft_monthselect{
	right: 0px;
}
.xdsoft_datetimepicker .xdsoft_label i {
    display: none;
}
.xdsoft_datetimepicker .xdsoft_label{
	background-color: #000;
	border: 1px solid $orange-color;
}
.xdsoft_datetimepicker .xdsoft_year {
	text-align: center;
    width: 62px;
}
.xdsoft_datetimepicker .xdsoft_label>.xdsoft_select{
	background: #000;
}
.xdsoft_prev, .xdsoft_next, .xdsoft_today_button{
	display:none!important;
	border: 1px solid $orange-color;
}
.xdsoft_today{
	color: #fff !important;
}
.xdsoft_calendar td.xdsoft_current {
    background: black !important;
    box-shadow: none !important;
    border: 1px solid $orange-color;
}
.xdsoft_datetimepicker .xdsoft_monthpicker {
    display: inline-table;
    margin-bottom: 15px;
}
.xdsoft_datetimepicker .xdsoft_datepicker {
	padding-left:0;
}
.xdsoft_datetimepicker .xdsoft_calendar td:hover, .xdsoft_datetimepicker .xdsoft_timepicker .xdsoft_time_box>div>div:hover {
	background: $orange-color;
	color:#fff;
}
.form-outline {
	.form-control {
		&:focus~.form-label {
			color: $orange-color;
			left:0;
			border:0!important;
			top: 0;
		}
		&.active~.form-label {
			top: 0;
		}
	}
	.form-control~.form-label {
		color: $orange-color;
		font-size:16px;
		padding:0px;
		left: 0;
		top: 10px;
		@media(max-width:768px){
			overflow: initial;
			white-space: break-spaces;
		}
	}
}

.form-outline .form-control.disabled, .form-outline .form-control:disabled, .form-outline .form-control[readonly] {
    background-color: rgba(0,0,0,0.9);
}
.form-control:invalid, .form-control:valid{
	background-image: none !important;
	margin-bottom: 0px !important;
}
.form-check-input[type=radio]:checked:after {
	border-color: #fff;
    background-color: #fff;
    width: 0.645rem;
}
.form-check-input[type=radio]:after {
    background-color: #000;
	height: unset;
}
.form-check-input, .form-check-input:focus {
	border-color: $orange-color;
	background-color: #000;
	border: 2px solid $orange-color;
}
.form-check-input[type=radio]:checked, .form-check-input[type=radio]:checked:focus {
    background-color: #000;
}
.form-check-input:checked, .form-check-input:checked:focus {
    border-color: $orange-color;
}
input[type=radio]:not(:checked)+span:after,
input[type=radio]:not(:checked)+span:before
{
    border: 2px solid $orange-color;
}
.form-check-input[type=radio] {
    width: 16px;
    height: 16px;
    margin-top: 5px;
}
input[type=radio].with-gap:checked+span:after,
input[type=radio]:checked+span:after{
    background-color: $orange-color;
}

input[type=radio].with-gap:checked+span:after,
input[type=radio].with-gap:checked+span:before,
input[type=radio]:checked+span:after{
    border: 2px solid $orange-color;
}
.tooltip {
    text-align: left;
	border:1px solid $orange-color;
    font-size:14px;
    font-weight: 300;
	line-height:1.5;
	padding:15px;
	color:#fff;
	background:#000;	
	max-width:330px;
	min-width:250px;
	letter-spacing: 0.045em;
}
.tooltip .tooltip-inner{
	width:100%;
	padding:0;
	max-width: 100%;
	font-family: 'Open Sans';
	text-align: left;
}
.tooltip.show {
    opacity: 1;
}
.tooltip-right-select{
    position: absolute;
    right: 0px;
    top: 10px;
}
.form-outline .form-control:focus~.form-notch .form-notch-leading, .form-outline .form-control:focus~.form-notch .form-notch-middle, .form-outline .form-control:focus~.form-notch .form-notch-trailing{
    border-color: $orange-color;
    box-shadow: none!important;
	border-top: 0;
}
.form-outline .form-control~.form-notch div {
	border:0;
	border-bottom:1px solid $orange-color;
	border-radius: 0!important;
	box-shadow:none!important;
}
.form-check-input.is-invalid~.form-check-label, .was-validated .form-check-input:invalid~.form-check-label {
    color: $red-color!important;
}
.invalid-feedback{
    font-size: 12.8px;
    margin-top: 0px;
}
.select-label{
    color: $orange-color;
    font-size: 12.8px;
    margin-top: -16px;
    position: absolute;
}

/*********** package switch form *****/.form-control:focus
.stepwizard-step p {
    margin-top: 10px;
}
.stepwizard-step {
	.btn-primary{
		background-color: #000!important;
		border: 1px solid #f7a400!important;
		box-shadow: none!important;
		color: #f7a400!important;
		margin: 0 auto;
		font-size: 24px;
		top: -15px;
		line-height: 43px;
		font-weight: 600;
	}
	.btn-default{
		background-color: #000!important;
		border: 1px solid #f7a400!important;
		box-shadow: none!important;
		color: #f7a400!important;
		margin: 0 auto;
		font-size: 24px;
		top: -15px;
		line-height: 43px;
		font-weight: 600;
	}
}
.stepwizard-row {
    display: table-row;
}

.stepwizard {
    display: table;
    width: 100%;
    position: relative;
	margin-top: 30px;
}

.stepwizard-step button[disabled] {
    opacity: 1 !important;
    filter: alpha(opacity=100) !important;
}
.stepwizard-row:before {
    top: 14px;
    bottom: 0;
    position: absolute;
    content: " ";
    width: 100%;
    height: 1px;
    background-color: #f7a400;
    //z-order: 0;

}
.stepwizard-step {
    display: table-cell;
    text-align: center;
    position: relative;
	span{
	    color: #f7a400!important;
		font-weight: 600;
		font-size: 14px;
		margin-top: -5px;
		display: block;
	}
}
.btn-circle {
	background: #000;
    border-radius: 50%;
    font-size: 12px;
    height: 60px;
    padding: 6px 0;
    text-align: center;
    width: 60px;
}
.btn-circle:hover {
	opacity:1;
}
.nextBtn, .prevBtn{
    background: #000;
    color: $orange-color;
    border: 1px solid $orange-color;
    border-radius: 0!important;
}

#modalDocumentsUrl {
	text-align: left;
	color: white;
}

/*********** form ***************/
#orderForm{
	#steps{
		@media(max-width:768px){
			padding: 0 15px;
		}
	}
	.header{
		margin-top:0px;
		margin-bottom:15px;
		.logo img{
			max-width: 170px;
   			margin-top: 15px;
			@media(max-width:768px){
				max-width: 128px;
			}
		}
		a{
			.icon-close{
				font-size: 21px;
				color: #fff;
				text-decoration: underline;
				transition: all .6s ease;
			}
		}
	}
	h1{
		color:#fff;
		font-size:24px;
		font-weight:700;
		margin-bottom: 0px;
		line-height: 1.5;	 
		@media(max-width:768px){
			text-align: center;
			letter-spacing: 0;
		}
	}
	.order-url{
	    font-size: 16px;
		font-weight: 400;
	}
	.close-section {
		margin-top: 15px;
	 }	 
	#formContent{
		margin-bottom:60px;
		hr{
			border-top: 1px solid #fff;
			margin-bottom: 0.9rem;
			opacity: 1;
			height: 0.5px;
		}
		.sectionTitle{
			font-size:18px;
			font-weight:700;
			padding-bottom:15px;
		}
		.check-field:has(.form-check-input.is-invalid) .form-section-title, .was-validated  .check-field:has(.form-check-input:invalid) .form-section-title {
			color: #dc4c64!important;
		}
		.check-field:has(.form-check-input.is-valid) .form-section-title, .was-validated  .check-field:has(.form-check-input:valid) .form-section-title {
			color: #14a44d!important;
		}
		.form-control.is-invalid, .was-validated .form-control:invalid {
			margin-bottom:40px!important;
			
		}
		.form-control.is-invalid~.invalid-feedback, .was-validated .form-control:invalid~.invalid-feedback {
			margin-top:-40px!important;
			
		}
		
		.steps-title{
			padding:30px 0 15px 0;
			border-bottom: 1px solid $orange-color;
			h3{
				color:rgba(255, 255, 255, .5);
				font-size:18px;
				font-weight:700;
				margin-bottom:0;
				&:active{
					color:#fff;
				}
			}
			&.active{
				h3{
					color:#fff!important;
					cursor:pointer;
				}
			}
		}
		.step[data-step="dane-kontaktowe"]{
			.steps-section-content {
				flex-direction: column;
			}
		}
		.step[data-step="wybor-pakietu"]{
			.steps-section-content {
				flex-direction: column;
				cursor:pointer;
				margin: 0;
    			padding: 30px 0px 0px 0px;
			}
			.steps-section-after-package{
				padding: 15px;
				@media(max-width:768px){
					padding: 15px 0px;
				}
			}
			.arrows{
				display: inline-block;
			}
			#clausule{
				margin: 0 15px;
				@media(max-width:768px){
					margin: 0;
				}
				p{
					font-size: 11px;
					line-height: 1.3rem;
					margin-bottom: 0;
					color:#fff;
					font-weight: 300;
					letter-spacing: .045em;
					@media(max-width:768px){
						letter-spacing: normal;
					}
				}
				span{
					text-decoration: underline !important;
				}
			}
			.package-head-title{
				font-size:16px;
				color:#fff;
				font-weight:700;
			}
			.package-description {
				color: white;
				text-align: center;
				font-weight: 200;
			}
			#packageItems{
				.package-item{
					border:1px solid $orange-color;
					padding: 15px;
					text-align: center;
					color: #fff;
					font-size: 16px;
					font-weight: 300;

					&.active{
						background:$orange-color;
					}
					&.first{
						border-radius: 28px 0 0 28px;
					}
					&.last{
						border-radius: 0 28px 28px 0;
					}
				}
				@media(max-width:768px){
					padding: 0 15px;
					.package-item{
						font-size: 12px;
						padding: 12px 2px;
					}
				}
			}
			#promotionCode{
				padding:0px;
				display:inline-block;
				.confirm-delete-button{
					border-bottom: 1px solid #f7a400;
					padding-top: 3px;
					color: #ffff;
					font-size: 16px;
					line-height: 42px;
					text-align: right;
					padding-right: 0;
					text-transform:uppercase;
				}
				#errorCouponInfo {
					color:$red;
					font-size:12px;
				}
				#successCouponInfo {
					color:green;
					font-size:12px;
				}
				.invalid{
					errorCouponInfo{
						color:$red!important;
					}
					.confirm-delete-button{
						border-bottom: 1px solid $red!important;
					}
					.form-control~.form-notch div {
						border-bottom: 1px solid $red!important;
					}
				}
			}
			#startContractDate{
				padding:0px;
				display:inline-block;
				.calendar-icon{
					border-bottom: 1px solid #f7a400;
					padding-top: 10px;
					color: $orange-color;
					font-size: 16px;
					text-align: right;
					padding-right: 0;
				}
			}
		}
		.step[data-step="dane-firmy"]{
			.steps-section-content {
				flex-direction: column;
				cursor:pointer;
			}
			.get-gus-data, .copy-address-holder{
				font-size: 14px;
				.spinner-border{
					width:15px;
					height:15px;
				}
			}
			.nip-content{
				margin-bottom: 5px;
			}
		}
		.step[data-step="potwierdzenie"]{
			.title{
				color: #fff;
				font-size: 24px;
				font-weight: 700;
				margin-bottom: 60px;
			}
			.text {
				color: #fff;
				font-size: 18px;
				padding: 0 0 60px;
				width: 50%;
				margin: 0 auto;
			}
		}
		.step[data-step="reprezentacja-firmy"]{
			#uploadedDocuments{
				ul{
					list-style:none;
					color:#fff;
					font-size:14px;
					padding-left:0;
				}
				.remove-documents{
					color: $orange-color;
					font-size:14px;
					cursor:pointer;
					font-weight:600;
					margin-left:15px;
				}
			}
			.label--checkbox {
				font-size: 14px;
				letter-spacing: 0;
				color: #fff;
				margin:0;
				input{
					@media(max-width:768px){
						margin-bottom: 110px;
					}
				}
			}
			.form-control {
				margin-top:0;
			}
			.representation-separate-title {
				color: #fff;
				font-size: 18px;
				font-weight: 700;
			}
			.representationNameSurname{
				@media(max-width:768px){
					margin-top: -12px;
				}
			}
			#formSendRepresentationData{
				.send-one-email {
					background: 0;
					border: 0;
					margin: 30px 0 15px 0!important;
					cursor: pointer!important;
					color: #f7a400!important;
					display: block;
					font-size:14px;
					span {
						color: #fff;
						padding-left: 0;
					}
					img{
						width:40px;
					}
				}
				.representation-separate-title {
					color: #fff;
					font-size: 18px;
					font-weight: 700;
				}
				.verification-method {
					margin: 0 15px;
					.verification-title {
						margin: 30px 0 15px 0;
						padding: 0;
						color: #fff;
						font-size:18px;
						font-weight:700;
						@media(max-width:768px){
							font-size:16px;
							text-align: center;
						}
					}
					.verification-column {
						display: inline-block;
						max-width: 539px;
						padding: 0 15px;
						width: 50%;
						@media(max-width:768px){
							width: 100%;
							padding: 0;
						}
						.verification-box {
							button{
								display:block;
								text-align:center;
								border: 1px solid #f7a400;
								width: 100%;
								background:#000;
								font-size: 16px;
								margin: 15px 30px 15px 0;
								padding: 15px;
								a {
									color: #f7a400;
									cursor: pointer;
								}
								.center{
									margin:0 auto;
								}
								span {
									color: #f7a400;
									text-transform: uppercase;
									font-weight: 600;
									margin-left: 15px;
									position: relative;
									top: 2px;
								}
							}
							.svg, .img {
								@media(max-width:768px){
									display: grid;
									margin: auto;
									margin-bottom: 15px;
								}
							}
						}
						&.left{
							float:left;
						}
						&.right{
							float:right;
						}
					}
					.verification-info{
						p{
							color: #fff;
							font-size:16px;
							font-weight:300;
							text-align: center;
						}
					}
				}
			}
			#sendRepresentationData{
				.send-one-email {
					background: 0;
					border: 0;
					margin: 30px 0 15px 0!important;
					cursor: pointer!important;
					color: #f7a400!important;
					display: block;
					font-size:14px;
					span {
						color: #fff;
						padding-left: 0;
					}
					img{
						width:40px;
					}
				}
				.representation-separate-title {
					color: #fff;
					font-size: 18px;
					font-weight: 700;
				}
				.verification-method {
					.verification-title {
						margin: 30px 0 15px 0;
						color: #fff;
						font-size:18px;
						font-weight:700;
					}
					
					.verification-column {
						display: inline-block;
						max-width: 539px;
						width: 50%;
						@media(max-width:768px){
							width: 100%;
							padding: 0;
						}
						.verification-box {
							button{
								display:block;
								text-align:center;
								border: 1px solid #f7a400;
								width: 100%;
								background:#000;
								font-size: 16px;
								margin: 15px 30px 15px 0;
								padding: 15px;
								a {
									color: #f7a400;
									cursor: pointer;
								}
								.center{
									margin:0 auto;
								}
								span {
									color: #f7a400;
									text-transform: uppercase;
									font-weight: 600;
									margin-left: 15px;
									position: relative;
									top: 2px;
								}
							}
						}
						&.left{
							float:left;
						}
						&.right{
							float:right;
						}
					}
					.verification-info{
						p{
							color: #fff;
							font-size:16px;
							font-weight:300;
							text-align: center;
						}
					}
				}
			}
			.status-verification {
				color: #fff;
				span{
					color: $orange-color;
					cursor: pointer;
				}
				&.rejected{
					color: red !important;
				}
				&.accepted{
					color: green;
				}
			}
			#addNextPerson,.add-next-person, .remove-person{
				font-weight: 700;
   				cursor: pointer;
				color: $orange-color;
			}
		}
		.step[data-step="beneficjenci"]{
			.info-text{
				color: #fff;
				font-size: 16px;
				font-weight: 300;
				.weight-700 {
					font-weight: 700;
				}
				@media(max-width:768px){
					font-size: 14px;
					letter-spacing: 0;
					line-height: 1.8;
					}
			}
			.line-bottom-separate{
				border-bottom:1px solid $orange-color;
				width:100%;
			}
			.accordion{
				padding: 0;
			}
			.collapse{
				.col-md-12{
					padding: 0px;
				}
			}
			.beneficiary-form-title{
				font-size: 18px;
				font-weight: 700;
				color: #fff;
			}
			#addNextBeneficiary, .add-next-beneficiary, .remove-beneficiary{
				font-weight: 700;
   				cursor: pointer;
				color: $orange-color;
				font-size: 16px;
			}
			.toogle-arrow-right {
				float: right;
				color: #FFF;
				margin-top: -30px;
			}
			.form-section-title{
				margin-top:0px;
			}
		}
		.step[data-step="status-platnosci"]{
			color:#fff;
			text-align: left;
			line-height: 30px;
			font-size: 16px;
			.container{
				padding:0;
			}
			.text{
				margin-bottom:90px;
			}
		}
		.step[data-step="umowa"]{
			.steps-section-content {
				flex-direction: column;
				cursor:pointer;
			}
			#sectionHeadText{
				color: #fff;
				font-size: 16px;
				line-height: 1.5;
				margin-bottom: 60px;
			}
			.load{
				background:rgba(0,0,0,.95);
			}
			.btn-primary{
				margin-top:60px;
			}
			.label--checkbox {
				font-size: 16px;
				color: #fff;
				margin:0;
			}
			.label--checkbox.validate-error{
				color: red !important;
			}
			#representationList{
				.name-surname{
					font-size: 16px;
					color: #fff;
				}
				.btn-primary{
					max-width: 100%;
					margin: 15px auto;
				}
				.sign{
					max-width: 290px;
					padding: 0;
					margin: 15px;
				}
				.verification-info {
					color:$red;
				}
				.signed{
					border: 1px solid red;
					background-color:#000;
					box-shadow: none!important;
					pointer-events: none;
				}
			}
			.checkbox {
				&:after {
					top: 15px;
				}
			}

		}
		.step[data-step="podsumowanie"]{
			color:#fff;
			text-align: left;
			line-height: 1.5;
			font-size: 16px;
			.container{
				padding:0;
			}
			.coupon-error{
				color: green;
				font-size: 12px;
				margin-top: 15px;
			}
			.old-price{
				text-decoration: line-through;
				opacity:0.6;
				margin-right:5px;
				font-size:14px;
			}
			span.small {
				font-size:14px;
				color:#a7a7a7;
			}
			.steps-section-content {
				flex-direction: column;
				cursor:pointer;
			}
			.inline-flex{
			    display: inline-flex;
				justify-content: end;
				i{
					color: $orange-color;
					margin-right:15px;
					cursor:pointer;
				}
			}
			.clausule-text{
			    margin: 30px 0 0;
				font-size: 11px;
				color:#fff;
			}
			.payment-item {
				span.small {
					font-size:14px;
					color:#a7a7a7;
				}
			}
			#orderForm #formContent hr {
				border-top: 1px solid $orange-color;
			}
			#couponCode{
				border: 0;
				border-bottom: 1px solid #f7a400;
				color: #fff;
				margin-bottom: 0;
				background: #000;
				font-size: 14px;
				padding: 13px;
			}
			.confirm-coupon {
				width: 24%;
				border: 0;
				background: #000;
				color: #fff;
				font-weight: 400;
				font-size: 12px;
				text-align: right;
				padding: 13px 0;
				border-bottom: 1px solid #f7a400;
				cursor: pointer;
			}
			
		}
		.steps-section-content{
			//border-top:1px solid $orange-color;
			border-left:1px solid $orange-color;
			border-right:1px solid $orange-color;
			border-bottom:1px solid $orange-color;
			width:100%;
			padding: 30px;
			display:none;
			position:relative;
			.clausule{
				p{
					color:#fff;
					font-size:14px;
					font-weight: 300;
					margin-top: 15px;
					margin-bottom: 0px;
					line-height:1.5;
				}
			}
			.load{
				background: rgba(0,0,0,.64);
				height: 100%;
				left: 0;
				position: absolute;
				top: 0;
				width: 100%;
				text-align: center;
				.loader{
					margin: 0 auto;
					margin-top: 50%;
				}
			}
			.head-title {
				border-bottom: 1px solid #fff;
				padding: 0px 0 15px 0;
				font-size: 16px;
				margin: 0;
				text-align: left;
				p{
					margin-bottom:0;
					text-transform:uppercase;
					font-weight:600;
				}
			}
			.radio-section {
				color: #f7a400;
				border-bottom: 1px solid #f7a400;
				text-align: left;
				margin-bottom: 15px;
				.form-check {
					margin: 0 0 30px 0;
					@media(max-width:768px){
						margin: 0 0 15px 0;
					}
				}
			}
			@media(max-width:768px){
				padding: 15px 0;
				border: none;
			}
		}
		
		.no-border{
			border:0px;
			padding: 30px 0;
		}
		.down-button-position{
		    position: absolute;
			bottom: 0;
			width: calc(100% - 30px);
		}
		.open-more-text {
			color: $orange-color;
			text-decoration:none;
			cursor:pointer;
			@media(max-width:768px){
				white-space: pre;
			}
		}
		#packages{
			.single-package {
				border: 1px solid #fff;
				padding-top:30px;
				margin-top:30px;
				height: calc(100% - 30px);
				max-width: 330px;
				@media(max-width:768px){
					max-width: 300px;
					margin-left: 10px;
    				margin-right: 10px;
				}
				.info{
					background:$orange-color;
					text-align:center;
					font-size:14px;
					line-height:30px;
					color:#000;
					font-weight:400;
					margin-bottom:30px;
				}
				.package-name{
					text-align:center;
					color:#fff;
					font-size:18px;
					font-weight: 700;
				}
				.price{
				    color: #fff;
					font-size: 30px;
					font-weight: 700;
					line-height: 1;
					margin-top: 25px;
					position: relative;
					text-align: center;
					.price-currency{
						font-size: 16px;
						font-weight: 300;
						letter-spacing: 2px;
						position: absolute;
						margin-left: 15px;
						bottom: 5px;
					
					}
				}
				.btn{
					border: 1px solid $red-color;
					background: $red-color;
					padding: 15px;
					font-size: 16px;
					letter-spacing: .1rem;
					height: auto;
					margin-top: 15px;
					text-transform: none;
					color: rgb(255, 255, 255) !important;
					width: calc(100% - 60px);
					justify-content: center;
					margin: 25px auto;
					display: flex;
					transition: .5s;
					&:hover{
						background: #000;
					}
					&.choosen{
						background: #000;
					}
					@media(max-width:768px){
						font-size: 14px;
					}
				}
				
			}	
			.reccomended{
				border:1px solid $orange-color;
				padding-top:0px;
				margin-top:0px;
				height: 100%;
				@media(max-width:768px){
					margin-top: 30px;
				}
			}
			.items{
				margin-bottom:15px;
				padding: 0 30px;
				@media(max-width:768px){
					padding: 0 15px;
				}
				.item{
					display:inline-flex;
					width: 100%;
					margin-bottom: 20px;
					.description{
						color:#fff;
						font-weight:300;
						font-size:13px;
						line-height: 25px;
						letter-spacing: .045rem;
						opacity: 1;
					    width: 270px;
						strong{
							font-weight: 700;
						}
					}
					.icon{
						position:relative;
						width:21px;
						margin-right: 18px;
						@media(max-width:768px){
							margin-right: 15px;
						}
					}
				}
			}
		}
		.addCardForm {.hs__item
			.clausule-card-text{
				p{
					line-height:1.5;
					font-size:12px;
				}
			}
		}
		#clausuleContent{
			color:#fff;
			.info-text{
				font-size: 16px;
				line-height: 1.5;
				font-weight: 200;
				letter-spacing: 0.045em;
				strong{
					font-weight:700;
				}
				@media(max-width:768px){
					font-size: 14px;
					letter-spacing: 0;
					line-height: 1.8;
					}
				}
			}
			.info-title{
				font-size: 16px;
				font-weight:700;
				letter-spacing: 0.045em;
			}
		}
		
		#uploadDocumentContent{
			.dropzone {
				min-height: 220px;
				border: 1px dashed #f7a400;
				background: #000;
				font-weight: 700;
				font-size: 16px;
				padding: 20px 20px;
				color: #fff;
				border-radius:0;
			}
			.title{
				font-weight:700;
				font-size:16px;
				color:#fff;
			}
			#add-documents {
				font-size: 16px;
				color: #f7a400;
				font-weight: 600;
				padding: 15px;
				border: 1px solid #FFFFFF;
				width: fit-content;
				margin: 30px 0;
				cursor:pointer;
				@media(max-width:768px){
					font-size: 14px;
    				letter-spacing: 0;
				}
			}
		}
		#representationPeople {
			border-top:1px solid #fff;
			margin-top:30px;
			padding-top:30px;
		}
	}
	.form-section-title{
		color: $orange-color;
		font-size: 16px;
		font-weight: 600;
		margin-bottom:15px;
		margin-top:15px;
	}
	.accounting_quesion-link{
		float: none;
	}
	.step-1-check{
		.form-check-label {
			font-weight: 400;
			color: $orange-color;
			font-size: 15px;
			margin-left: 5px;
			@media(max-width:768px){
				font-size: 14px;
				width: 100%;
				letter-spacing: 0;
			}
		}
		.form-check{
			padding-left: 25px;
			padding-right: 5px;
			@media(max-width:768px){
				margin-bottom: 15px;
			}
		}
		@media(max-width:768px){
			margin-bottom: 15px !important;
		}
	}
	.line-before-btn{
		margin-bottom: 45px;
    	border-bottom: solid $orange-color 1px;
	}
.display-none{
	display:none!important;
}
#modal{
	.modal-dialog {
		min-height: calc(100vh - 60px);
		max-width: 600px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		overflow: auto;
		@media(max-width: 768px) {
			min-height: calc(100vh - 20px);
		}
		.close-btn{
			background:#000;
		}
		.modal-content {
			background: #000;
			border: 1px solid #f7a400;
			color: #fff;
			border-radius: 0px;
			.icon-close {
				color: #fff;
				font-size: 14px;
				text-decoration: underline;
				transition: all .6s ease;
			}
			.modal-header {
				background:#000;
				color:#fff;
				border:0;
				align-self: flex-end;
				padding:15px 15px 0 0;
			}
			.display-block{
				display:block!important;
			}
			.modal-body{
				font-size: 14px;
    			padding: 30px 30px 0 30px;
			}
			.modal-footer{
				background:#000;
				color:#fff;
				border:0;
				display: inline-flex;
				justify-content: space-between;
				padding: 15px 30px 30px 30px;
				.btn{
					width:auto;
					margin: 0px;
				}
				a {
					border: 0;
					color: #fff;
					cursor: pointer;
					font-size: 12px;
					margin: auto;
					text-align: center;
					padding: 15px;
					width: 100%;
				}
				a:hover {
					opacity: 1 !important;
				}
				span {
					color: $orange-color;
					cursor: pointer;
					background: #000;
					border: 0;
					font-size: 12px;
					text-transform: none;
				}
				.btn-modal {
					background: red;
					border: 1px solid red;
					color: #fff;
					display: inline-flex;
    				width: 100%;
					cursor: pointer;
					font-size: 12px;
					line-height: 1.5;
					margin: 0px;
					text-transform: none;
				}
				.btn-modal.black {
					background: black !important;
				}
				.btn-modal:hover {
					background: black !important;
				}
			}
		}	
	}
}
#modalContractLogin{
	.modal-dialog {
		min-height: calc(100vh - 60px);
		max-width: 460px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		overflow: auto;
		@media(max-width: 768px) {
			min-height: calc(100vh - 20px);
		}
		.close-btn{
			background:#000;
		}
		.close-modal{
			color: #fff;
			float: right;
		}
		.modal-content {
			background: #000;
			border: 1px solid #f7a400;
			color: #fff;
			border-radius: 0px;
			.btn-primary{
				line-height: 1.2rem;
			}
			.icon-close {
				color: #fff;
				font-size: 14px;
				text-decoration: underline;
				transition: all .6s ease;
			}
			.modal-header {
				background:#000;
				color:#fff;
				border:0;
				align-self: flex-end;
				padding:15px 15px 0 0;
			}
			.display-block{
				display:block!important;
			}
			.modal-body{
				font-size: 14px;
    			padding: 30px 30px 0 30px;
				.btn-black {
					background: black !important;
					font-size: 14px;
    				line-height: 1.2rem;
					letter-spacing: 0.045em;
				}
			}
			.modal-footer{
				background:#000;
				color:#fff;
				border:0;
				display: inline-flex;
				justify-content: space-between;
				padding: 15px 30px 30px 30px;
				.btn{
					width:auto;
					margin: 0px;
				}
				a {
					border: 0;
					color: #fff;
					cursor: pointer;
					font-size: 12px;
					margin: auto;
					text-align: center;
					padding: 15px;
					width: 100%;
				}
				a:hover {
					opacity: 1 !important;
				}
				span {
					color: $orange-color;
					cursor: pointer;
					background: #000;
					border: 0;
					font-size: 12px;
					text-transform: none;
				}
				.btn-modal {
					background: red;
					border: 1px solid red;
					width: 50%;
					color: #fff;
					cursor: pointer;
					font-size: 12px;
					display: flex;
					line-height: 1.5;
					margin: 0px;
					text-transform: none;
				}
				.btn-modal:hover {
					background: black !important;
				}
			}
		}	
	}
}
/************* ACCESSORIES ********************/
.loader {
  width:12px;
  height:12px;
  border-radius: 50%;
  color: #f7a400;
  clip-path: inset(-25px -100px);
  box-shadow: 19px -40px,38px -40px,57px -40px;
  transform: translateX(-38px);
  animation: d7 1s infinite;
}

@keyframes d7{ 
  16.67% {box-shadow: 19px  0px,38px -40px,57px -40px}
  33.33% {box-shadow: 19px  0px,38px   0px,57px -40px}
  45%,55%{box-shadow: 19px  0px,38px   0px,57px   0px}
  66.67% {box-shadow: 19px 40px,38px   0px,57px   0px}
  83.33% {box-shadow: 19px 40px,38px  40px,57px   0px}
  100%   {box-shadow: 19px 40px,38px  40px,57px  40px}
}
.icon-close {
	&:before {
		content: '\e872';
	}
}
.icon-copy {
	&:before {
		content: '\e875';
	}
}
.choose-package-icon{
	background-color: #f7a400;
    transform: translateY(-50%);
    display: block;
    height: 21px;
	width: 21px;
    background-size: 24px;
    -webkit-mask: url(/images/icons/check-fill.svg) no-repeat center/contain;
    mask: url(/images/icons/check-fill.svg) no-repeat center/contain;
    top: 50%;
    position: absolute;
}
.choose-package-icon-checked{
	background-color: #f7a400;
    transform: translateY(-50%);
    display: block;
    height: 21px;
	width: 21px;
    background-size: 24px;
    -webkit-mask: url(/images/icons/checked_circle.svg) no-repeat center/contain;
    mask: url(/images/icons/checked_circle.svg) no-repeat center/contain;
    top: 50%;
    position: absolute;
}
.signed-icon{
	background-color: #f7a400;
    display: block;
    height: 24px;
	width: 21px;
    background-size: 24px;
    -webkit-mask: url(/images/icons/check-fill.svg) no-repeat center/contain;
    mask: url(/images/icons/check-fill.svg) no-repeat center/contain;
	margin-right: 15px;
}
.signed {
	.signed-icon {
    	height: 36px;
	}
}
.tooltip-icon {
    display: inline-block;
	-webkit-mask: url(/images/icons/tooltip.svg) no-repeat center/contain;
    mask: url(/images/icons/tooltip.svg) no-repeat center/contain;
    background-color: #f7a400;
    width: 14px;
    height: 14px;
	margin: 5px 0px 5px 10px;
	vertical-align: middle;
    cursor: pointer;
	@media(max-width:768px){
		float: right;
	}
}
.required{
	font-size:12px;
	color:$red-color;
	margin-right: 7px;
}

.select2-container--material {
  width: 100% !important;
}
.select2-container--material .select2-selection--single {
    background-color: transparent;
    border: none;
    border-bottom: 1px solid #f7a400;
    border-radius: 0;
    box-shadow: none;
    box-sizing: content-box;
    color: #fff;
    font-family: 'Open Sans',sans-serif;
    font-size: 18px;
    font-weight: 400;
    height: 36px;
    margin: 0;
    outline: none;
    padding: 0 0 2px 0;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.select2-container--material
  .select2-selection--single
  .select2-selection__rendered {
	color: #fff;
    font-size: 16px;
    line-height: 28px;
    padding-left: 0;
    padding: 0 0 3px 0;
}
.select2-container--material
  .select2-selection--single
  .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold;
}
.select2-container--material
  .select2-selection--single
  .select2-selection__placeholder {
  color: #999;
}
.select2-container--material
  .select2-selection--single
  .select2-selection__arrow {
  height: 26px;
  margin: 0.6rem 0 0.4rem 0;
  position: absolute;
  top: 1px;
  right: 1px;
  width: 20px;
}
.select2-container--material
  .select2-selection--single
  .select2-selection__arrow
  b {
  border-color: #888 transparent transparent transparent;
  border-style: solid;
  border-width: 5px 4px 0 4px;
  height: 0;
  left: 50%;
  margin-left: -4px;
  margin-top: -8px;
  position: absolute;
  top: 50%;
  width: 0;
}
.select2-container--material[dir="rtl"]
  .select2-selection--single
  .select2-selection__clear {
  float: left;
}
.select2-container--material[dir="rtl"]
  .select2-selection--single
  .select2-selection__arrow {
  left: 1px;
  right: auto;
}
.select2-container--material.select2-container--disabled
  .select2-selection--single {
  background-color: transparent;
  cursor: default;
}
.select2-container--material.select2-container--disabled
  .select2-selection--single
  .select2-selection__clear {
  display: none;
}
.select2-container--material.select2-container--open
  .select2-selection--single
  .select2-selection__arrow
  b {
  border-color: transparent transparent #888 transparent;
  border-width: 0 4px 5px 4px;
}
.select2-container--material .select2-selection--multiple {
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #ced4da;
  border-radius: 0;
  box-shadow: none;
  box-sizing: content-box;
  cursor: text;
  height: auto;
  margin: 0;
  outline: none;
  padding: 5px 0 0 0;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.select2-container--material
  .select2-selection--multiple
  .select2-selection__rendered {
  box-sizing: border-box;
  list-style: none;
  margin: 0;
  padding: 0 5px;
  width: 100%;
}
.select2-container--material
  .select2-selection--multiple
  .select2-selection__rendered
  li {
  list-style: none;
}
.select2-container--material
  .select2-selection--multiple
  .select2-selection__placeholder {
  color: #999;
  margin-top: 5px;
  float: left;
}
.select2-container--material
  .select2-selection--multiple
  .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold;
  margin-top: 5px;
  margin-right: 10px;
}
.select2-container--material
  .select2-selection--multiple
  .select2-selection__choice {
  background-color: #ffca28;
  border-radius: 16px;
  color: rgba(0, 0, 0, 0.6);
  cursor: default;
  float: left;
  margin-right: 5px;
  margin-top: 6px;
  padding: 0 12px;
}
.select2-container--material
  .select2-selection--multiple
  .select2-selection__choice__remove {
  cursor: pointer;
  display: inline-block;
  font-weight: bold;
  float: right;
  margin-left: 5px;
}
.select2-container--material
  .select2-selection--multiple
  .select2-selection__choice__remove:hover {
  color: #333;
}
.select2-container--material[dir="rtl"]
  .select2-selection--multiple
  .select2-selection__choice,
.select2-container--material[dir="rtl"]
  .select2-selection--multiple
  .select2-selection__placeholder,
.select2-container--material[dir="rtl"]
  .select2-selection--multiple
  .select2-search--inline {
  float: right;
}
.select2-container--material .select2-selection--single .select2-selection__rendered {
	padding: 0 0 6px;
	margin-top: 9px;
}
.select2-container--material[dir="rtl"]
  .select2-selection--multiple
  .select2-selection__choice {
  margin-left: 5px;
  margin-right: auto;
}
.select2-container--material[dir="rtl"]
  .select2-selection--multiple
  .select2-selection__choice__remove {
  margin-left: 2px;
  margin-right: auto;
}
.select2-container--material.select2-container--disabled
  .select2-selection--multiple {
  background-color: transparent;
  cursor: default;
}
.select2-container--material.select2-container--disabled
  .select2-selection__choice__remove {
  display: none;
}
.select2-container--material.select2-container--open.select2-container--above
  .select2-selection--single,
.select2-container--material.select2-container--open.select2-container--above
  .select2-selection--multiple {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.select2-container--material.select2-container--open.select2-container--below
  .select2-selection--single,
.select2-container--material.select2-container--open.select2-container--below
  .select2-selection--multiple {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.select2-container--material.select2-container--focus
  .select2-selection--single {
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  outline: 0;
}
.select2-container--material.select2-container--focus
  .select2-selection--multiple {
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  outline: 0;
}
.select2-container--material .select2-search--dropdown .select2-search__field {	
  border: none;
  color:#fff;
  border-bottom: 1px solid #ced4da;
  border-radius: 0;
  outline: none;
}
.select2-container--material
  .select2-search--dropdown
  .select2-search__field:focus:not([readonly]) {
  box-shadow: 0 1px 0 0 #ced4da;
  border-bottom: 1px solid #ced4da;
}
.select2-container--material .select2-search--inline .select2-search__field {
  background: transparent;
  border: none !important;
  outline: 0;
  box-shadow: none !important;
  -webkit-appearance: textfield;
}
.select2-container--material .select2-results > .select2-results__options {
  max-height: 200px;
  overflow-y: auto;
}
.select2-container--material .select2-results__option[role="group"] {
  padding: 0;
}
.select2-container--material .select2-results__option[aria-disabled="true"] {
  color: #999;
}
.select2-container--material .select2-results__option[aria-selected="true"] {
	background-color: #000;
    border: 1px solid $orange-color;
}
.select2-container--material .select2-results__option .select2-results__option {
  padding-left: 1em;
}
.select2-container--material
  .select2-results__option
  .select2-results__option
  .select2-results__group {
  padding-left: 0;
}
.select2-container--material
  .select2-results__option
  .select2-results__option
  .select2-results__option {
  margin-left: -1em;
  padding-left: 2em;
}
.select2-container--material
  .select2-results__option
  .select2-results__option
  .select2-results__option
  .select2-results__option {
  margin-left: -2em;
  padding-left: 3em;
}
.select2-container--material
  .select2-results__option
  .select2-results__option
  .select2-results__option
  .select2-results__option
  .select2-results__option {
  margin-left: -3em;
  padding-left: 4em;
}
.select2-container--material
  .select2-results__option
  .select2-results__option
  .select2-results__option
  .select2-results__option
  .select2-results__option
  .select2-results__option {
  margin-left: -4em;
  padding-left: 5em;
}
.select2-container--material
  .select2-results__option
  .select2-results__option
  .select2-results__option
  .select2-results__option
  .select2-results__option
  .select2-results__option
  .select2-results__option {
  margin-left: -5em;
  padding-left: 6em;
}
.select2-container--material
  .select2-results__option--highlighted[aria-selected] {
  background-color: $orange-color;
  color: white;
}
.select2-container--material .select2-results__group {
  cursor: default;
  display: block;
  padding: 6px;
}

.select2-dropdown {
  background-color: #000;
  border: 1px solid $orange-color;
  border-radius: 0;
  box-sizing: border-box;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  display: block;
  position: absolute;
  left: -100000px;
  width: 100%;
  z-index: 1051;
  -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16),
    0 2px 10px 0 rgba(0, 0, 0, 0.12);
}

.select2-results {
  display: block;
}

.select2-results__options {
  list-style: none;
  margin: 0;
  padding: 0;
}

.select2-results__option {
  padding: 6px;
  color:white;
  user-select: none;
  -webkit-user-select: none;
}
.select2-results__option[aria-selected] {
  cursor: pointer;
  color:#fff;
}
.choose-nip-none, .representation-check {
	color:$orange-color;
    cursor: pointer;
    display: block;
    font-size: 1rem;
    height: 25px;
    line-height: 25px;
    margin-top: 25px;
    padding-left: 0;
    position: relative;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
}
.representation-check {
    padding-left: 0px;
}
.select2-container--open .select2-dropdown {
  left: 0;
}
.select2-container--open .select2-dropdown--above {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.select2-container--open .select2-dropdown--below {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.select2-search--dropdown {
  display: block;
  padding: 4px;
}
.select2-search--dropdown .select2-search__field {
  padding: 4px;
  width: 100%;
  box-sizing: border-box;
  background: #000;
}
.select2-search--dropdown .select2-search__field::-webkit-search-cancel-button {
  -webkit-appearance: none;
}
.select2-search--dropdown.select2-search--hide {
  display: none;
}
.was-validated{
	.validate-error {
		.select2-selection__rendered {
			color:#dc4c64 !important;
		}
		.select2-selection--single{
			border-bottom: 1px solid #dc4c64;
		}
		.select-label{
			color: red !important;
			margin-top: 48px;
		}
	}
	.validate-success{
		.select2-selection__rendered {
			color:#fff !important;
		}
		.select2-selection--single{
			border-bottom: 1px solid #14a44d;
		}
		.select-label{
			color: #14a44d !important;
		}
	}
}
#floating-contact-container {
	position: fixed;
	top: 170px;
	right: -1px;
	width: 53px;
	height: 53px;
	padding: 5px;
	border: 1px solid #f7a400;
	border-right: 0 solid transparent;
	color: #fff;
	background: #000;
	font-size: 21px;
	padding-left: 19px;
    text-align: left;
	line-height: 40px;
	cursor: pointer;
	z-index: 1111;
	a {
		color: #fff !important;
	}
	i {
		margin: 0px -9px 0;
		border: 0;
		position: inherit;
	}
	#slideout_inner {
		position: fixed;
		top: 170px;
		right: -200px;
		background: #000;
		height: 53px;
		border-top: 1px solid #f7a400;
		border-bottom: 1px solid #f7a400;
		width: 200px;
		line-height: 3rem;
		padding: 1px 5px;
		-webkit-transition-duration: 0.5s;
		transition-duration: 0.5s;
	}
	&:hover {
		right: 200px;
		-webkit-transition-duration: 0.5s;
		transition-duration: 0.5s;
		a{
			border: 0;
			float: left;
			height: 49px;
			font-size: 18px;
			line-height: 49px;
			font-weight: 600;
			text-decoration: none;
		}
		#slideout_inner {
			right: 0;
		}
	}
}

.label--checkbox {
	position: relative;
	margin: 5px;
    line-height: 25px;
	cursor: pointer;
	display:flex;
}
.checkbox,.representation-check .label--checkbox {
	margin:0;
}
#sumOld{
	text-decoration: line-through;
	font-size:14px;
}
.checkbox {
	position: relative;
	margin: 0 1.5rem 0 0;
	cursor: pointer;
	&:before {
		background: #000;
		border: 2px solid #5a5a5a;
		content: "";
		height: 18px;
		width: 18px;
		left: 0;
		position: absolute;
		top: 2px;
		transition: all .3s ease-in-out;
		z-index: 1;
	}
	&:checked {
		&:before {
			border-color: #f7a400;
			border-right-style: none;
			border-top-style: none;
			height: 12px;
			transform: rotate(-45deg);
			width: 22px;
			left:2px;
		}
	}
	&:after {
		background: #000;
		content: "";
		height: 38px;
		left: 0;
		position: absolute;
		top: -0.125rem;
		width: 18px;
	}
}
.margin-form-bottom{
	margin-bottom: 30px!important;
}

.page-loader-inside {
	width: 56px;
	height: 56px;
	display: grid;
	animation: spinner-plncf9 4.8s infinite;
    margin: 30px auto;
}

.page-loader-inside::before,
.page-loader-inside::after {
   content: "";
   grid-area: 1/1;
   border: 9px solid;
   border-radius: 50%;
   border-color: #f7a400 #f7a400 #0000 #0000;
   mix-blend-mode: darken;
   animation: spinner-plncf9 1.2s infinite linear;
}

.page-loader-inside::after {
   border-color: #0000 #0000 #ede3cf #ede3cf;
   animation-direction: reverse;
}

@keyframes spinner-plncf9 {
   100% {
      transform: rotate(1turn);
   }
}


@-webkit-keyframes slideUp {
  0% {
    -webkit-transform: translateY(6.25rem);
    transform: translateY(6.25rem);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
@keyframes slideUp {
  0% {
    -webkit-transform: translateY(6.25rem);
    transform: translateY(6.25rem);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  color: #fff;
  opacity: 1;
  background-color: #000;
  box-shadow: inset 0 0 0 220px #000 !important;
  -webkit-text-fill-color: #fff;
}
input:-webkit-autofill {
  color: #fff;
  opacity: 1;
  background-color: #000;
  box-shadow: inset 0 0 0 220px #000 !important;
  -webkit-text-fill-color: #fff;
}
input:-internal-autofill-selected {
  color: #fff;
  opacity: 1;
  background-color: #000;
  box-shadow: inset 0 0 0 220px #000 !important;
  -webkit-text-fill-color: #fff;
}
input:-webkit-autofill:focus {
  color: #fff!important;
  opacity: 1!important;
  background-color: #000!important;
  box-shadow: 0 0 0 1000px #000 inset!important;
  -webkit-text-fill-color: #fff!important;
} 

::-webkit-input-placeholder {
  opacity: 1;
  background-color: #000;
  color: #fff;
  box-shadow: inset 0 0 0 220px #000 !important;
}

::-moz-placeholder {
  opacity: 1;
  background-color: #000;
  color: #fff;
  box-shadow: inset 0 0 0 220px #000 !important;
}

:-ms-input-placeholder {
  opacity: 1;
  background-color: #000;
  color: #fff;
  box-shadow: inset 0 0 0 220px #000 !important;
}

::-ms-input-placeholder {
  opacity: 1;
  background-color: #000;
  color: #fff;
  box-shadow: inset 0 0 0 220px #000 !important;
}


//packages
* {
			  box-sizing: border-box;
			}
			::-webkit-scrollbar {
			  /* Webkit */
			  width: 0;
			  height: 0;
			}
			.single-item{
				padding: 0;
			}
			.hs {
			  display: flex;
			  //overflow-x: scroll;
			  justify-content: space-between;
			  scrollbar-width: none;
			  /* Firefox */
			  -ms-overflow-style: none;
			  /* IE 10+ */
			  -webkit-overflow-scrolling: touch;
			  margin: 0 15px;
    		  padding: 0;
			  @media(max-width:768px){
				display: block;
				margin: 0;
    			padding: 0;
				overflow-x: unset;
			  }
			}
			.hs__item {
				flex-grow: 1;
				flex-shrink: 0;
				flex-basis: 330px;
				margin: 0 15px;
				padding: 0;
				max-width: 330px;
				display: flex;
				justify-content: center;
				align-items: center;
				flex-direction: column;
				position: relative;
				-webkit-user-select: none;
					 -moz-user-select: none;
					  -ms-user-select: none;
						  user-select: none;
			}
			@media only screen and (max-width: 768px) {
				.hs__item {
					flex-basis: calc(100% / 4 - (10px * 2) - (20px / 4));
					margin: 0;
					max-width: unset;
				}
				
			}
			.hs__item:last-child:after {
			  content: "";
			  display: block;
			  position: absolute;
			  width: 10px;
			  height: 1px;
			  //right: calc(10px * 2 * -1);
			}
			.hs__item__content__wrapper {
			  position: relative;
			  padding: 0.75rem 1rem;
			  width: 100%;
			  height: 100%;
			  border-radius: 5px;
			}
			@media only screen and (min-width: 990px) {
			  .hs__wrapper {
				overflow: hidden;
			  }
			}
			@media (hover: none) and (pointer: coarse) {
			  .hs__wrapper .hs__item {
				flex: 1 0 100%;
			  }
			}
			@media only screen and (hover: none) and (pointer: coarse) and (max-width: 990px) {
				.hs__wrapper .hs__item {
					flex: 1 0 100%;
				}
				.hs__item:first-child {
					margin-left: 0px;
				}
			}