

@mixin button-variant($color, $background, $border) {
    $active-background: darken($background, 5%);
    $active-border: darken($border, 5%);

    color: $color;
    background-color: $background;
    border-color: $border;
    &:focus, &.focus, &:hover, &.active, &:active, &:disabled, &.disabled
    {
        color: $color !important;
        background-color: $active-background !important;
        border-color: $active-border !important;
        box-shadow: none;
        background-image: none;
    }
}

@mixin button-outline-variant($color, $color-hover: #fff) {
    color: $color;
    background-image: none;
    background-color: transparent;
    border-color: $color;

    &:focus, &.focus, &:hover, &.active, &:active {
        color: $color-hover !important;
        background-color: $color !important;
        border-color: $color !important;
        box-shadow: none;
        background-image: none;
    }
}

@mixin button-label($padding, $label-padding, $label-margin) {
    padding-right: $padding;
    .btn-label {
        padding: $label-padding;
        margin-right: $label-margin;
    }
    &.right {
        padding-right: 0;
        padding-left: $padding;
        .btn-label {
            margin-right: 0;
            margin-left: $label-margin;
        }
    }
}