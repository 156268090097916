@font-face {
    font-family: check;
    font-display: swap;
    src: url(fonts/check.eot?21825267);
    src: url(fonts/check.eot?21825267#iefix) format("embedded-opentype"), url(fonts/check.woff2?21825267) format("woff2"), url(fonts/check.woff?21825267) format("woff"), url(fonts/check.ttf?21825267) format("truetype"), url(fonts/check.svg?21825267#check) format("svg");
    font-weight: 400;
    font-style: normal
}

[class*=" icon-"]:before,
[class^=icon-]:before {
    font-family: check;
    font-style: normal;
    font-weight: 400;
    speak: none;
    display: inline-block;
    text-decoration: inherit;
    width: 1em;
    margin-right: .2em;
    text-align: center;
    font-variant: normal;
    text-transform: none;
    line-height: 1em;
    margin-left: .2em;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
}

.icon-check:before {
    content: '\e800'
}

.icon-certificate:before {
    content: '\e801'
}

.icon-experience:before {
    content: '\e802'
}

.icon-creative:before {
    content: '\e803'
}

.icon-247:before {
    content: '\e804'
}

.icon-logo-gp:before {
    content: '\e805';
    width: auto
}

.icon-flexible:before {
    content: '\e821'
}

.icon-virtualoffice:before {
    content: '\e822'
}

.icon-comprehensive:before {
    content: '\e823'
}

.icon-coworking-1:before {
    content: '\e859'
}

.icon-accounting:before {
    content: '\e85f'
}

.icon-biurowiec:before {
    content: '\e860'
}

.icon-sala-konferencyjna:before {
    content: '\e861'
}

.icon-lock-1:before {
    content: '\e862'
}

.icon-gwarancja:before {
    content: '\e863'
}

.icon-odbieranie-telefonu:before {
    content: '\e864'
}

.icon-arrow-down:before {
    content: '\e86a'
}

.icon-arrow-left:before {
    content: '\e86b'
}

.icon-arrow-right:before {
    content: '\e86c'
}

.icon-arrow-up:before {
    content: '\e86d'
}

.icon-question:before {
    content: '\e86e'
}

.icon-comfort:before {
    content: '\e86f'
}

.icon-cross:before {
    content: '\e870'
}

.icon-info-1:before {
    content: '\e871'
}

.icon-close:before {
    content: '\e872'
}

.icon-phone:before {
    content: '\e873'
}

.icon-chat:before {
    content: '\e874'
}

.icon-copy:before {
    content: '\e875'
}

.icon-location:before {
    content: '\e876'
}

.icon-contact:before {
    content: '\e879'
}

.icon-calendar:before {
    content: '\e87a'
}

.icon-clients:before {
    content: '\e87b'
}

.icon-staff:before {
    content: '\e87e'
}

.icon-sign:before {
    content: '\e880'
}

.icon-company-formation:before {
    content: '\e881'
}

.icon-contact2:before {
    content: '\e882'
}

a.close {
    position: inherit;
    padding: 1.1rem 0 0;
    color: #fff;
    cursor: pointer;
}