.select2-container--default .select2-selection--single {
  border: 1px solid #ced4da;
  padding: 0.375rem 0.75rem;
  height: calc(1.5em + 0.75rem + 2px);
  width: 100%;
  border-radius: 2px;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
  padding: 0;
  line-height: inherit;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: $primary;}

.select2-container--classic .select2-selection--single:focus {
  border: 1px solid $primary; }

.select2-container--classic.select2-container--open .select2-selection--single {
  border: 1px solid $primary; }

.select2-container--classic .select2-selection--multiple:focus {
  border: 1px solid $primary; }

.select2-container--classic.select2-container--open .select2-selection--multiple {
  border: 1px solid $primary; }

.select2-container--classic .select2-results__option--highlighted[aria-selected] {
  background-color: $primary;}

.select2-container--classic.select2-container--open .select2-dropdown {
  border-color: $primary; }

.select2-results__option {
    min-height: 32px;
}
