@import 'vars';

@import '~bootstrap/scss/bootstrap';
@import '~@fortawesome/fontawesome-free/css/all.css';
@import '~bootstrap-table/dist/bootstrap-table.min';
@import '~select2/dist/css/select2.css';
@import "~daterangepicker/daterangepicker.css";
@import "~lightbox2/dist/css/lightbox.css";

@import 'vendors/vendors';
@import 'overide/overide';
@import 'mixins/mixins';
@import 'components/components';
@import 'utilities/utilities';
@import "dateTimePicker";
@import '../common';
@import 'vendors/bootstrap-table.min.scss';
@import 'mdb/mdb.min.css';
@import "icons";
@import "styles";
