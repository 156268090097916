.ibox .ibox-footer {
  padding: 10px 20px;
}

.ibox-head-left b {
  font-size: 1.1rem;
}

.mb-1rem {
  margin-bottom: 1rem;
}

.hide-labels {
  label {
    display: none;
  }
}

.data-box-row {
  display: flex;
  flex-wrap: wrap;
}

.data-box-title {
  flex-basis: 0;
  -webkit-box-flex: 1;
  flex-grow: 1;
  width: auto;
  max-width: 150px;
  padding-right: 7.5px;
  text-align: right;
  background: $gray-200;
  padding-top: 5px;
  padding-bottom: 5px;
  font-weight: bold;
}

.data-box-content {
  padding-left: 7.5px;
  flex-basis: 0;
  -webkit-box-flex: 1;
  flex-grow: 1;
  width: calc(100% - 200px);
  background: $gray-100;
  padding-top: 5px;
  padding-bottom: 5px;
}

.data-box + .data-box {
  margin-top: 25px;
}

.clients-chooser {
  position: relative;
  overflow: auto;
  max-height: calc(100vh - 200px);
  width: auto;
  height: auto;
}

.nav-item {
  font-size: 15px;
}
.client-link{
    text-align: right;
    margin-top: -44px;
    /* margin-right: 15px; */
    font-size: 20px;
	transform: translate(-7px, 19px);
}
i.fas.fa-link {
	transform: rotateZ(44deg);
	color: #5b44f2;
}
thead {
    background: #f6f9fc;
    color: #a3a6b4;
}
.table-bordered {
    border: none !important;
}
.select2-container--default .select2-selection--single .select2-selection__arrow b{
	margin-top: -6px;
}
